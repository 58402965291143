import { ReactComponent as UsersIcon } from "../../assets/ic24-users.svg";
import { Link } from "react-router-dom";
import "./CleanerManager.scss";

const CleanerManager = () => {
  return (
    <div className="cleaner-profile">
      <Link to="/cleanermain" className="order-list__back">
        Назад
      </Link>
      <div className="cleaner-profile__header">
        <UsersIcon className="cleaner-profile__icon" />

        <p className="cleaner-profile__id">За Вами закреплен Менеджер</p>
        <h2 className="">Михаил</h2>
      </div>
      Номер телефона:{" "}
      <a className="cleaner-manager__phone" href="tel:+79853529039">
        +7 985 352 90 39
      </a>
      <div className="contact-buttons">
        <a href="tel:+79853529039" className="contact-button call">
          Позвонить
        </a>
        <a
          href="https://wa.me/79853529039"
          target="_blank"
          rel="noopener noreferrer"
          className="contact-button whatsapp">
          Написать WhatsApp
        </a>
      </div>
    </div>
  );
};

export default CleanerManager;
