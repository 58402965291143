import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "./CleanerMain.scss";
import { selectIsAuth } from "../../redux/slices/auth";
import { fetchUserInfo, fetchUserInfoChange } from "../../redux/slices/user";
import { fetchCleanerOrders } from "../../redux/slices/order";

import { ReactComponent as UserIcon } from "../../assets/UserCleaner.svg";
import { ReactComponent as CalendarIcon } from "../../assets/CalendarCleaner.svg";
import { ReactComponent as UsersIcon } from "../../assets/ic24-users.svg";

const CleanerMain = () => {
  const isAuth = useSelector(selectIsAuth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ordersState = useSelector(state => state.order);
  const { items: orders, status } = ordersState.order;

  useEffect(() => {
    dispatch(fetchUserInfo());
    dispatch(fetchUserInfoChange());
  }, [dispatch]);

  const [isLoading, setIsLoading] = useState(true);
  const { user } = useSelector(state => state.user);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login-cleaner");
  };

  const [upcomingOrder, setUpcomingOrder] = useState(null);

  useEffect(() => {
    if (!user?.items?._id) {
      console.log("⏳ Ожидание загрузки ID клинера...");
      return;
    }

    console.log("🚀 Отправляем запрос на заказы для клинера:", user.items._id);
    dispatch(fetchCleanerOrders(user.items._id));
  }, [dispatch, user?.items?._id]);

  useEffect(() => {
    if (!orders || orders.length === 0) return;

    const now = new Date();
    const threeDaysLater = new Date();
    threeDaysLater.setDate(now.getDate() + 3);

    // Фильтруем заказы, которые попадают в ближайшие 3 дня
    const upcomingOrders = orders
      .filter(order => {
        const targetDate = new Date(order.date);
        return targetDate >= now && targetDate <= threeDaysLater;
      })
      .sort((a, b) => a.date - b.date); // Сортируем по ближайшей дате

    if (upcomingOrders.length > 0) {
      setUpcomingOrder(upcomingOrders[0]); // Берем самый ближайший заказ
    } else {
      setUpcomingOrder(null); // Если нет заказов — сбрасываем
    }
  }, [orders]);

  const totalEarnings = useMemo(() => {
    return orders
      ?.filter(order => order.state === true) // Считаем только выполненные заказы (state === true)
      .reduce((sum, order) => sum + (order.cleanerEarnings || 0), 0);
  }, [orders]);

  return (
    <div className="dashboard">
      <div className="dashboard__header">
        <Link to="/cleanerorders" className="dashboard__notification">
          {upcomingOrder ? (
            <>
              <span className="icon">🔔</span>
              <p>Новый заказ на завтра!</p>
              <span className="count">1</span>
            </>
          ) : (
            <p>Нет новых заказов</p>
          )}
        </Link>
        <div className="dashboard__earnings">
          <span className="icon">💲</span>
          <p>Вы заработали!</p>
          <span className="amount">{Math.trunc(totalEarnings)} ₽</span>
        </div>
      </div>
      <div className="dashboard__profile">
        <div className="dashboard__profile-info">
          <p>{user.items.name ? user.items.name : "Имя не указано"}</p>
        </div>
      </div>
      <div className="cleaner-menu">
        <Link to="/cleanerprofile" className="cleaner-menu__item">
          <UserIcon className="cleaner-profile__icon" />
          <span>Профиль</span>
        </Link>
        <Link to="/cleanerorders" className="cleaner-menu__item">
          <CalendarIcon className="cleaner-profile__icon" />
          <span>Календарь заказов</span>
        </Link>
        <Link to="/cleanermanager" className="cleaner-menu__item">
          <UsersIcon className="cleaner-profile__icon" />
          <span>Связь с менеджером</span>
        </Link>
      </div>
      <div className="dashboard__actions">
        <Link to="/cleanerorders" className="dashboard__button">
          Заказы
        </Link>
        <Link to="/cleanerrevenue" className="dashboard__button">
          Доходы
        </Link>
        <Link to="/cleanercalendar" className="dashboard__button">
          Выбрать выходной или отпуск
        </Link>

        <button className="dashboard__button" onClick={handleLogout}>
          Выйти
        </button>
      </div>
    </div>
  );
};

export default CleanerMain;
