// import { Link, useNavigate } from "react-router-dom";
// import { YandexLogin } from "../../components/yandex";
// import "../authorization/Authorization.scss";
// import { useForm } from "react-hook-form";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchAuth } from "../../redux/slices/auth";
// import { useState } from "react";
// const clientID = "38fad74eb4444c649c22b27c1a04f3ac";

// const Auth = () => {
//   let navigate = useNavigate();
//   const dispatch = useDispatch();

//   const { isPhoneChecked, isSendingCode, idCode } = useSelector(
//     state => state.auth
//   );
//   const [authCode, setAuthCode] = useState("");
//   const loginSuccess = userData => {};
//   const { register, handleSubmit, getValues } = useForm({
//     defaultValues: {
//       login: "",
//       password: "",
//     },
//   });

//   const handleAuthCode = e => {
//     setAuthCode(e.target.value);
//   };

//   const onSubmit = async values => {
//     const dataSend = {
//       ...values,
//       code: authCode,
//       idCode,
//     };

//     const data = await dispatch(fetchAuth(dataSend));
//     window.localStorage.setItem("formClose", false);
//     window.localStorage.setItem("token", data.payload.accessToken);

//     if (!data.payload) {
//       return alert("Не удалось авторизоваться!");
//     }

//     if (data.payload) {
//       window.localStorage.setItem("token", data.payload.accessToken);
//       navigate("/");
//     }
//   };
//   return (
//     <div className="authorization">
//       <div
//         className="authorization__employees"
//         style={{
//           margin: "0 auto",
//           justifyContent: "center",
//         }}>
//         <form onSubmit={handleSubmit(onSubmit)}>
//           <YandexLogin clientID={clientID} onSuccess={loginSuccess}>
//             <button className="nav__sign-modal-input nav__sign-modal-input-yandex">
//               <img
//                 src="https://yastatic.net/s3/home/services/all/all_lite/Search_v3.svg"
//                 alt="yandex"
//                 height="30px"
//               />
//               Войти с Яндекс ID
//             </button>
//           </YandexLogin>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default Auth;

import { Link, useNavigate } from "react-router-dom";
import { YandexLogin } from "../../components/yandex";
import "../authorization/Authorization.scss";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { fetchAuthEmail, fetchRegisterEmail } from "../../redux/slices/auth";
import { useState } from "react";

const clientID = "38fad74eb4444c649c22b27c1a04f3ac";

const Auth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { status } = useSelector(state => state.auth);

  const [isRegister, setIsRegister] = useState(false);
  const { register, handleSubmit } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async values => {
    const action = isRegister ? fetchRegisterEmail : fetchAuthEmail;
    const response = await dispatch(action(values));

    if (response.payload?.accessToken) {
      localStorage.setItem("token", response.payload.accessToken);
      navigate("/");
    } else {
      console.error(
        "Ошибка авторизации/регистрации:",
        response.payload?.message
      );
    }
  };

  return (
    <div className="authorization">
      <div className="authorization__container">
        <h2>{isRegister ? "Регистрация" : "Авторизация"}</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-group">
            <label>Email</label>
            <input
              type="email"
              placeholder="Введите email"
              {...register("email")}
              required
            />
          </div>
          <div className="input-group">
            <label>Пароль</label>
            <input
              type="password"
              placeholder="Введите пароль"
              {...register("password")}
              required
            />
          </div>
          <button type="submit">
            {isRegister ? "Зарегистрироваться" : "Войти"}
          </button>
        </form>
        {status === "loading" && <p>Загрузка...</p>}
        {status === "error" && <p>Ошибка! Проверьте данные</p>}
        <p>
          {isRegister ? "Уже есть аккаунт?" : "Нет аккаунта?"}{" "}
          <span
            className="toggle-auth"
            onClick={() => setIsRegister(!isRegister)}>
            {isRegister ? "Войти" : "Регистрация"}
          </span>
        </p>
        <YandexLogin clientID={clientID} onSuccess={() => navigate("/")}>
          <button className="yandex-btn">
            <img
              src="https://yastatic.net/s3/home/services/all/all_lite/Search_v3.svg"
              alt="yandex"
              height="30px"
            />
            Войти с Яндекс ID
          </button>
        </YandexLogin>
      </div>
    </div>
  );
};

export default Auth;
