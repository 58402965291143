import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./SideMenu.scss";
import Finance from "../../assets/sideMenuIcons/finance.svg";
import Order from "../../assets/sideMenuIcons/order.svg";
import Dashboard from "../../assets/sideMenuIcons/dashboard.svg";
import Calendar from "../../assets/sideMenuIcons/calendar.svg";
import Users from "../../assets/sideMenuIcons/users.svg";
import Setting from "../../assets/sideMenuIcons/setting.svg";
import { fetchUser } from "../../redux/slices/user";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const SideMenu = () => {
  const [active, setActive] = useState(1);

  const handleClick = index => {
    setActive(index);
  };

  const dispatch = useDispatch();

  const { user } = useSelector(state => state.user);
  const users = Object.values(user.items);
  const status = user.status;

  // Загружаем пользователей при монтировании компонента
  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  // Фильтруем только клинеров
  const cleaners = Array.isArray(users)
    ? users.filter(user => user.role?.name === "Уборщик")
    : [];

  return (
    <div className="side-menu">
      <div className="side-menu__profile">
        <img
          className="side-menu__section-list-item-avatar"
          src="https://via.placeholder.com/30"
          alt="Влада"
        />
        <div className="side-menu__info">
          <span className="side-menu__role">МЕНЕДЖЕР</span>
          <span className="side-menu__name">Андрей</span>
        </div>
      </div>
      <nav className="side-menu__nav">
        <ul className="side-menu__nav-list">
          <li
            className={`side-menu__nav-list-item ${
              active === 1 ? "side-menu__nav-list-item--active" : ""
            }`}
            onClick={() => handleClick(1)}>
            <Link to="/" className="side-menu__nav-list-item-link">
              <span className="side-menu__nav-list-item-icon">
                <img src={Dashboard} alt="" />
              </span>
              Dashboard
            </Link>
          </li>
          <li
            className={`side-menu__nav-list-item ${
              active === 2 ? "side-menu__nav-list-item--active" : ""
            }`}
            onClick={() => handleClick(2)}>
            <Link to="/finance" className="side-menu__nav-list-item-link">
              <span className="side-menu__nav-list-item-icon">
                <img src={Finance} alt="" />
              </span>
              Финансы
            </Link>
          </li>
          <li
            className={`side-menu__nav-list-item ${
              active === 3 ? "side-menu__nav-list-item--active" : ""
            }`}
            onClick={() => handleClick(3)}>
            <Link to="/order" className="side-menu__nav-list-item-link">
              <span className="side-menu__nav-list-item-icon">
                <img src={Order} alt="" />
              </span>
              Заказы
            </Link>
          </li>
          <li
            className={`side-menu__nav-list-item ${
              active === 4 ? "side-menu__nav-list-item--active" : ""
            }`}
            onClick={() => handleClick(4)}>
            <Link to="/archive" className="side-menu__nav-list-item-link">
              <span className="side-menu__nav-list-item-icon">
                <img src={Order} alt="" />
              </span>
              Архив Заказов
            </Link>
          </li>
          <li
            className={`side-menu__nav-list-item ${
              active === 5 ? "side-menu__nav-list-item--active" : ""
            }`}
            onClick={() => handleClick(5)}>
            <Link to="/calendar" className="side-menu__nav-list-item-link">
              <span className="side-menu__nav-list-item-icon">
                <img src={Calendar} alt="" />
              </span>
              Календарь Заказов
            </Link>
          </li>
          <li
            className={`side-menu__nav-list-item ${
              active === 6 ? "side-menu__nav-list-item--active" : ""
            }`}
            onClick={() => handleClick(6)}>
            <Link to="/cleaners" className="side-menu__nav-list-item-link">
              <span className="side-menu__nav-list-item-icon">
                <img src={Users} alt="" />
              </span>
              Заявки от Клинеров
            </Link>
          </li>
          <li
            className={`side-menu__nav-list-item ${
              active === 7 ? "side-menu__nav-list-item--active" : ""
            }`}
            onClick={() => handleClick(7)}>
            <Link
              to="/cleanerorderspage"
              className="side-menu__nav-list-item-link">
              <span className="side-menu__nav-list-item-icon">
                <img src={Users} alt="" />
              </span>
              Заказы по клинерам
            </Link>
          </li>
          <li
            className={`side-menu__nav-list-item ${
              active === 8 ? "side-menu__nav-list-item--active" : ""
            }`}
            onClick={() => handleClick(8)}>
            <Link to="/users" className="side-menu__nav-list-item-link">
              <span className="side-menu__nav-list-item-icon">
                <img src={Users} alt="" />
              </span>
              Клиенты
            </Link>
          </li>
          <li
            className={`side-menu__nav-list-item ${
              active === 9 ? "side-menu__nav-list-item--active" : ""
            }`}
            onClick={() => handleClick(9)}>
            <Link to="/promo" className="side-menu__nav-list-item-link">
              <span className="side-menu__nav-list-item-icon">
                <img src={Setting} alt="" />
              </span>
              Промокоды
            </Link>
          </li>
          <li
            className={`side-menu__nav-list-item ${
              active === 10 ? "side-menu__nav-list-item--active" : ""
            }`}
            onClick={() => handleClick(10)}>
            <Link to="/setting" className="side-menu__nav-list-item-link">
              <span className="side-menu__nav-list-item-icon">
                <img src={Setting} alt="" />
              </span>
              Настройки
            </Link>
          </li>
        </ul>
      </nav>

      <div className="side-menu__section">
        <h3 className="side-menu__section-title">
          КЛИНЕРЫ
          {/* <span className="side-menu__section-title-icon">➕</span> */}
        </h3>
        <ul className="side-menu__section-list">
          {status === "loading" ? (
            <p>Загрузка...</p>
          ) : cleaners.length > 0 ? (
            <ul className="side-menu__section-list">
              {cleaners.map(cleaner => (
                <li
                  key={cleaner._id}
                  className="side-menu__section-list-item"
                  style={{
                    fontSize: "13px",
                  }}>
                  {cleaner.email || "Неизвестный"}
                </li>
              ))}
            </ul>
          ) : (
            <p>Нет клинеров</p>
          )}
        </ul>
      </div>
    </div>
  );
};
