import React from "react";
import { Link } from "react-router-dom";
import "./CleanerProfile.scss";
import { ReactComponent as UserIcon } from "../../assets/UserCleaner.svg";
import mirLogo from "../../assets/mircard.svg";
// import alfaLogo from "../../assets/alfa-logo.png";

const CleanerProfile = () => {
  return (
    <div className="cleaner-profile">
      <Link to="/cleanermain" className="order-list__back">
        Назад
      </Link>
      <div className="cleaner-profile__header">
        <UserIcon className="cleaner-profile__icon" />
        <h2 className="cleaner-profile__name">Клинер – АННА</h2>
        <p className="cleaner-profile__id">Ваш личный номер (001)</p>
        <p className="cleaner-profile__income">35 700</p>
        <p className="cleaner-profile__desc">Вы заработали</p>
      </div>

      <div className="cleaner-profile__payment">
        <p className="cleaner-profile__requisites">
          Ваш реквизиты для вывода денег
        </p>
        <div className="cleaner-profile__card">
          <div className="card__top">
            <span className="card__bank">A</span>
            <img src={mirLogo} alt="МИР" className="card__mir" />
          </div>
          <div className="card__number">**** **** **** 7223</div>
          <div className="card__bottom">
            <div className="card__holder">
              <span className="label">Card Holder</span>
              <span className="value">Akbarali Khasanov</span>
            </div>
            <div className="card__expires">
              <span className="label">Expires</span>
              <span className="value">03/26</span>
            </div>
          </div>
        </div>
        <div>Оформить карту</div>
        <a href="https://alfa.me/gj-iMY" className="cleaner-profile__btn">
          Альфа Банк
        </a>
      </div>
    </div>
  );
};

export default CleanerProfile;
