import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { fetchAuthEmail } from "../../redux/slices/auth";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/logo-login.svg";
import "./LoginEmail.scss";

export const LoginEmail = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { status } = useSelector(state => state.auth);
  const navigate = useNavigate();
  const handleRegister = async e => {
    e.preventDefault();

    const response = await dispatch(fetchAuthEmail({ email, password }));

    if (response.payload?.accessToken) {
      localStorage.setItem("token", response.payload.accessToken);
      navigate("/cleanermain");
    } else {
      console.error("Ошибка авторизации:", response.payload?.message);
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <img src={Logo} alt="лого" width={187} />

        <p className="login-subtitle">Вход для сотрудника клинера</p>
        <form onSubmit={handleRegister} className="login-form">
          <input
            type="email"
            placeholder="Login"
            value={email}
            onChange={e => setEmail(e.target.value)}
            className="login-input"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            className="login-input"
          />
          <button type="submit" className="login-button">
            Авторизация
          </button>
        </form>
      </div>
    </div>
  );
};
