import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axios";

// Получение заказа
export const fetchOrder = createAsyncThunk("order/fetchOrder", async () => {
  const { data } = await axios.get("/api/order");
  return data;
});

// Удаление заказа
export const fetchOrderDelete = createAsyncThunk(
  "order/fetchOrderDelete",
  async id => {
    const { data } = await axios.delete(`/api/order/${id}`);
    return data;
  }
);

// Изменение заказа
// export const fetchOrderChange = createAsyncThunk(
//   "order/fetchOrderChange",
//   async ({ id, state }) => {
//     const { data } = await axios.put(`/api/order/${id}`, { state });
//     return data;
//   }
// );
export const fetchOrderChange = createAsyncThunk(
  "order/fetchOrderChange",
  async ({ id, state, isCanceled }) => {
    console.log("📌 Отправка изменений заказа:", { id, state, isCanceled });

    const { data } = await axios.put(`/api/order/${id}`, {
      state,
      ...(isCanceled !== undefined && { isCanceled }), // Отправляем isCanceled только если он передан
    });

    return data;
  }
);

// Отмена заказа
export const fetchOrderCancel = createAsyncThunk(
  "order/fetchOrderCancel",
  async id => {
    const { data } = await axios.put(`/api/order/cancel/${id}`);
    return data;
  }
);

// Получение заказов для клинера
export const fetchCleanerOrders = createAsyncThunk(
  "order/fetchCleanerOrders",
  async (cleanerId, { rejectWithValue }) => {
    try {
      console.log(`⚡ Отправляем запрос: /api/cleaner/${cleanerId}/orders`);
      const { data } = await axios.get(`/api/cleaner/${cleanerId}/orders`);
      console.log("🟢 Получены заказы:", data);
      return data;
    } catch (error) {
      console.error("🔴 Ошибка загрузки заказов", error.response?.data);
      return rejectWithValue(error.response?.data || "Ошибка загрузки заказов");
    }
  }
);

const initialState = {
  order: {
    items: [],
    status: "loading",
  },
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  extraReducers: {
    // Получение заказа
    [fetchOrder.pending]: state => {
      state.order.status = [];
      state.order.status = "loading";
    },
    [fetchOrder.fulfilled]: (state, actions) => {
      state.order.items = actions.payload;
      state.order.status = "loaded";
    },
    [fetchOrder.rejected]: state => {
      state.order.status = [];
      state.order.status = "error";
    },
    // Удаление заказа
    [fetchOrderDelete.pending]: (state, actions) => {
      state.order.items = state.order.items.filter(
        obj => obj._id === actions.payload
      );
    },
    [fetchOrderDelete.fulfilled]: (state, actions) => {
      state.order.items = actions.payload;
      state.order.status = "loaded";
    },
    [fetchOrderDelete.rejected]: state => {
      state.order.status = [];
      state.order.status = "error";
    },
    // Изменение заказа
    [fetchOrderChange.pending]: state => {
      state.order.status = [];
      state.order.status = "loading";
    },
    [fetchOrderChange.fulfilled]: (state, actions) => {
      state.order.items = actions.payload;
      state.order.status = "loaded";
    },
    [fetchOrderChange.rejected]: state => {
      state.order.status = [];
      state.order.status = "error";
    },
    // Отмена заказа
    [fetchOrderCancel.pending]: state => {
      state.order.status = "loading";
    },
    [fetchOrderCancel.fulfilled]: (state, actions) => {
      state.order.status = "loaded";
    },
    [fetchOrderCancel.rejected]: state => {
      state.order.status = "error";
    },
    // Получение заказов для клинера
    [fetchCleanerOrders.pending]: state => {
      state.order.status = "loading";
    },
    [fetchCleanerOrders.fulfilled]: (state, actions) => {
      state.order.items = actions.payload;
      state.order.status = "loaded";
    },
    [fetchCleanerOrders.rejected]: state => {
      state.order.status = "error";
    },
  },
});

export const orderReducer = orderSlice.reducer;
