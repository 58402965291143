import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  fetchAssignCleaner,
  fetchRemoveCleaner,
  fetchUser, // Чтобы обновлять список после изменения роли
} from "../../redux/slices/user";
import "./UsersCard.scss";
import { useForm } from "react-hook-form";
import { fetchRoleChange } from "../../redux/slices/role";

const UsersCard = ({
  userName,
  phone,
  email,
  balance,
  address,
  role,
  bonusBalance,
  userId,
}) => {
  const dispatch = useDispatch();
  const [orderId, setOrderId] = useState("");
  const [loading, setLoading] = useState(false);
  const [removing, setRemoving] = useState(false);

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      role: "",
    },
  });

  const onSubmit = async ({ id, role }) => {
    await dispatch(fetchRoleChange({ id, rules: { add: false, role } }));
    dispatch(fetchUser());
  };

  const handleRoleChange = (id, role) => {
    setValue("role", role); // устанавливаем выбранную роль в форме
    onSubmit({ id, role }); // отправляем запрос на изменение роли
  };

  const handleAssignCleaner = async () => {
    if (!orderId) {
      alert("Введите ID заказа");
      return;
    }
    setLoading(true);

    const response = await dispatch(
      fetchAssignCleaner({
        orderId,
        cleanerId: userId,
        paymentType: "percentage", // Добавляем paymentType
      })
    );

    setLoading(false);

    if (response.error) {
      alert("Ошибка: " + response.payload);
    } else {
      alert("Клинер успешно назначен!");
      setOrderId(""); // Очистить поле после назначения
    }
  };

  const handleRemoveCleaner = async () => {
    if (!orderId) {
      alert("Введите ID заказа");
      return;
    }
    setRemoving(true);

    const response = await dispatch(fetchRemoveCleaner(orderId));

    setRemoving(false);

    if (response.error) {
      alert("Ошибка: " + response.payload);
    } else {
      alert("Клинер удалён из заказа!");
      setOrderId(""); // Очистить поле после удаления
    }
  };

  return (
    <div className="users-card">
      <div className="users-card__info">
        <h2 className="users-card__name">{userName || "Имя не указано"}</h2>
        <p>{phone === "no-phone" ? "Телефон не указан" : phone}</p>
        <p className="users-card__email">{email}</p>
        <p className="users-card__email">{role?.name}</p>
      </div>
      <div className="users-card__address">
        <h3 className="users-card__address-title">Адрес</h3>
        {address && address.length > 0 ? (
          <>
            <p>{`город - ${address[0]}`}</p>
            <p>{`улица - ${address[1]}`}</p>
            <p>{`Квартира - ${address[2]}`}</p>
          </>
        ) : (
          <p className="users-card__no-address">Адрес не добавлен</p>
        )}
      </div>

      <form
        onSubmit={handleSubmit(data =>
          onSubmit({ id: userId, role: data.role })
        )}
        style={{
          width: "124px",
          margin: "18px 10px",
        }}>
        <select
          {...register("role")}
          className="users-card__select"
          value={role?.name || ""}
          onChange={e => handleRoleChange(userId, e.target.value)}>
          <option value="Администратор">Администратор</option>
          <option value="Менеджер">Менеджер</option>
          <option value="Пользователь">Пользователь</option>
          <option value="Уборщик">Уборщик</option>
        </select>
      </form>

      {role?.name === "Уборщик" && (
        <div
          className="users-card__assign"
          style={{
            width: "146px",
          }}>
          <input
            type="text"
            placeholder="ID заказа"
            value={orderId}
            onChange={e => setOrderId(e.target.value)}
            disabled={loading || removing}
          />
          <button
            onClick={handleAssignCleaner}
            disabled={loading}
            className="users-card__button users-card__button--remove">
            {loading ? "Назначение..." : "Назначить клинера"}
          </button>
          <button
            onClick={handleRemoveCleaner}
            disabled={removing}
            className="users-card__button users-card__button--remove">
            {removing ? "Удаление..." : "Удалить клинера"}
          </button>
        </div>
      )}
      <div className="users-card__actions">
        <div className="users-card__balance">
          <div className="users-card__balance-title">Баланс</div>
          <p className="users-card__balance-value">{balance} руб.</p>
        </div>
        <div className="users-card__buttons">
          <button className="users-card__button users-card__button--add">
            добавить Баллы
          </button>
          <button className="users-card__button users-card__button--delete">
            удалить аккаунт
          </button>
        </div>
      </div>
    </div>
  );
};

export default UsersCard;
