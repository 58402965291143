import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrder } from "../../redux/slices/order";
import "./CleanerOrdersPage.scss";

const CleanerOrdersPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchOrder())
      .then(() => setIsLoading(false))
      .catch(() => {
        setIsLoading(false);
        setError("Не удалось загрузить заказы. Попробуйте еще раз.");
      });
  }, [dispatch]);

  // Получаем заказы
  const { order } = useSelector(state => state.order);
  const ordersArray = Array.isArray(order.items) ? order.items : [];

  console.log("📌 Все заказы:", ordersArray);

  // Фильтруем только заказы, где есть "cleaner"
  const filteredOrders = ordersArray.filter(order => order.cleaner);

  console.log(
    "📌 Отфильтрованные заказы (только с клинерами):",
    filteredOrders
  );

  // Группируем заказы по email или name клинера
  const groupedOrders = filteredOrders.reduce((acc, order) => {
    const cleanerKey =
      order.cleaner.email || order.cleaner.name || "Неизвестный клинер";

    if (!acc[cleanerKey]) acc[cleanerKey] = [];
    acc[cleanerKey].push(order);
    return acc;
  }, {});

  console.log("📌 Группировка заказов по клинерам:", groupedOrders);

  return (
    <div className="order-cleaner__page">
      <h1 className="order-cleaner__title">Заказы по клинерам</h1>

      {isLoading && (
        <p className="order-cleaner__loading">Загрузка заказов...</p>
      )}
      {error && <p className="order-cleaner__error">{error}</p>}

      {!isLoading && !error && Object.keys(groupedOrders).length > 0 ? (
        <div className="order-cleaner__list">
          {Object.keys(groupedOrders).map(cleanerKey => (
            <div key={cleanerKey} className="order-cleaner__card">
              <div className="order-cleaner__card__header">
                <h3 className="order-cleaner__card__name">{cleanerKey}</h3>
              </div>
              <div className="order-cleaner__list">
                {groupedOrders[cleanerKey].map(order => (
                  <div key={order._id} className="order-cleaner__item">
                    <p className="order-cleaner__item__number">
                      Заказ №{order.orderNumber}
                    </p>
                    <p className="order-cleaner__item__type">{order.type}</p>
                    <p className="order-cleaner__item__type">
                      Время заказа:
                      {new Date(order.date).toLocaleString()}
                    </p>
                    <p className="order-cleaner__item__address">
                      {order.address}
                    </p>
                    <p className="order-cleaner__item__price">
                      {order.price} ₽
                    </p>
                    <p
                      className={`order-cleaner__item__status ${
                        order.isPaid
                          ? "order-cleaner__item__status--paid"
                          : "order-cleaner__item__status--unpaid"
                      }`}>
                      {order.isPaid ? "Оплачен" : "Не оплачен"}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="order-cleaner__no-orders">
          Нет заказов с назначенными клинерами
        </p>
      )}
    </div>
  );
};

export default CleanerOrdersPage;
