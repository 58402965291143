import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchCleanerOrders } from "../../redux/slices/order";
import "./CleanerOrders.scss";
import { fetchUserInfo, fetchUserInfoChange } from "../../redux/slices/user";

const CleanerOrders = () => {
  const dispatch = useDispatch();
  const ordersState = useSelector(state => state.order);
  const { items: orders, status } = ordersState.order;

  const { user } = useSelector(state => state.user);

  useEffect(() => {
    dispatch(fetchUserInfo());
    dispatch(fetchUserInfoChange());
  }, [dispatch]);

  useEffect(() => {
    if (!user?.items?._id) {
      console.log("⏳ Ожидание загрузки ID клинера...");
      return;
    }

    console.log("🚀 Отправляем запрос на заказы для клинера:", user.items._id);
    dispatch(fetchCleanerOrders(user.items._id));
  }, [dispatch, user?.items?._id]);

  console.log("📌 Redux Orders state:", ordersState);

  const formatDate = timestamp => {
    const now = new Date();
    const targetDate = new Date(timestamp);
    const diffMs = targetDate - now;

    if (diffMs <= 0) {
      return "Уборка уже прошла";
    }

    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor((diffMs / (1000 * 60 * 60)) % 24);
    const diffMinutes = Math.floor((diffMs / (1000 * 60)) % 60);

    // Форматируем дату по шаблону
    const formattedDate = `${targetDate.getDate()} ${targetDate.toLocaleString(
      "ru-RU",
      { month: "long" }
    )} ${targetDate.getFullYear()} год, ${targetDate.toLocaleTimeString(
      "ru-RU",
      {
        hour: "2-digit",
        minute: "2-digit",
      }
    )}`;

    const timeLeft =
      diffDays > 0
        ? `${diffDays} д. ${diffHours} ч. ${diffMinutes} мин.`
        : diffHours > 0
        ? `${diffHours} ч. ${diffMinutes} мин.`
        : `${diffMinutes} мин.`;

    return `${formattedDate} (через ${timeLeft})`;
  };

  const parseAddress = (rawAddress = "") => {
    const parts = rawAddress
      .split(",")
      .map(p => p.trim())
      .filter(Boolean);

    const city = parts[0] || "";
    const street = parts[1] || "";
    const house = parts[2] || "";

    // Предполагаем, что всё, что идёт после дома — может быть квартирой
    const tail = parts.slice(3);

    // Ищем "кв N" или просто номер квартиры в конце
    let apartment = "";

    for (let i = 0; i < tail.length; i++) {
      const part = tail[i];

      // Если это "кв 4"
      if (/^кв\s*\d+$/i.test(part)) {
        apartment = part;
      }

      // Если это просто число, и оно не дублирует уже найденное
      else if (/^\d+$/.test(part) && !apartment.includes(part)) {
        apartment = `кв ${part}`;
      }
    }

    return { city, street, house, apartment };
  };

  const { city, street, house } = parseAddress(orders.address);
  console.log("📌 Parsed address:", { city, street, house });

  if (status === "loading") {
    return <p>Загрузка заказов...</p>;
  }

  return (
    <div className="order-list">
      <Link to="/cleanermain" className="order-list__back">
        Назад
      </Link>
      <h2
        style={{
          textAlign: "center",
        }}>
        Ваши заказы
      </h2>

      <div className="cleaner-orders">
        {Array.isArray(orders) && orders.length > 0 ? (
          orders
            .slice()
            .reverse()
            .map(order => {
              const { city, street, house, apartment } = parseAddress(
                order.address
              );

              return (
                <div key={order._id} className="cleaner-order">
                  <div className="cleaner-order__header">
                    <span className="cleaner-order__number">
                      Заказ №{order.orderNumber}
                    </span>
                    <div className="cleaner-order__status-container">
                      <span
                        className={`cleaner-order__status ${
                          order.isCanceled
                            ? "canceled"
                            : order.isPaid
                            ? "paid"
                            : "pending"
                        }`}>
                        {order.isCanceled
                          ? "Отменен"
                          : order.isPaid
                          ? "Оплачен"
                          : "В ожидании"}
                      </span>
                    </div>
                  </div>

                  <p className="cleaner-order__address">
                    <strong>Город:</strong> {city} <br />
                    <strong>Улица:</strong> {street} <br />
                    <strong>Дом:</strong> {house} <br />
                    {apartment && (
                      <>
                        <strong>Квартира:</strong> {apartment}
                      </>
                    )}
                  </p>

                  <div className="cleaner-order__details">
                    <span className="cleaner-order__date">
                      <strong>{formatDate(order.date)}</strong>
                    </span>
                    <span>
                      Имя Заказчика:{" "}
                      <strong>
                        {order.user?.name
                          ? order.user.name
                          : order.user_name || "Не указано"}
                      </strong>
                    </span>

                    <span>
                      Телефон заказчика:{" "}
                      {order.user?.phone_number ? (
                        <a href={`tel:${order.user.phone_number}`}>
                          <strong>{order.user.phone_number}</strong>
                        </a>
                      ) : (
                        <strong>Не указан</strong>
                      )}
                    </span>

                    <span>
                      Домофон: <strong>{order.intercom}</strong>
                    </span>
                    <span>
                      Тип уборки: <strong>{order.type}</strong>
                    </span>
                    <span>
                      Жилье: <strong>{order.group}</strong>
                    </span>
                    <span>
                      Комнат: <strong>{order.room_count}</strong>, Ванных:{" "}
                      <strong>{order.bathroom_count}</strong>
                    </span>
                    <span>
                      Комментарий: <strong>{order.comment || "Нет"}</strong>
                    </span>
                    <span>
                      Оплата: <strong>{order.payment_type}</strong>
                    </span>
                  </div>

                  {order.additional_services?.length > 0 && (
                    <div className="cleaner-order__services">
                      <h4>Доп. услуги:</h4>
                      <ul>
                        {order.additional_services.map(service => (
                          <li key={service.id}>
                            {service.name} x {service.count}
                            {/* →{" "} */}
                            {/* <strong>{service.price * service.count} ₽</strong> */}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {order.cleaner.paymentType === "fixed" ? null : (
                    <span>
                      Работа Клинера:{" "}
                      <strong>{Math.trunc(order.cleanerEarnings)} ₽</strong>
                    </span>
                  )}
                </div>
              );
            })
        ) : (
          <p>Заказов пока нет</p>
        )}
      </div>
    </div>
  );
};

export default CleanerOrders;
