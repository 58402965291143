import React, { useEffect, useState } from "react";
import "./CleanerRevenue.scss";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCleanerOrders } from "../../redux/slices/order";
import { fetchUserInfo, fetchUserInfoChange } from "../../redux/slices/user";

const CleanerRevenue = () => {
  const dispatch = useDispatch();
  const ordersState = useSelector(state => state.order);
  const { items: orders, status } = ordersState.order;

  const { user } = useSelector(state => state.user);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedDay, setSelectedDay] = useState("");

  const months = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ];

  useEffect(() => {
    dispatch(fetchUserInfo());
    dispatch(fetchUserInfoChange());
  }, [dispatch]);

  useEffect(() => {
    if (!user?.items?._id) return;
    dispatch(fetchCleanerOrders(user.items._id));
  }, [dispatch, user?.items?._id]);

  const formatDate = time => {
    const date = new Date(time);
    return {
      day: date.getDate(),
      month: months[date.getMonth()],
      year: date.getFullYear(),
      time: date.toTimeString().slice(0, 5),
    };
  };

  const filteredOrders = Array.isArray(orders)
    ? orders.filter(order => {
        const date = new Date(order.date);
        const matchMonth = selectedMonth
          ? months[date.getMonth()] === selectedMonth
          : true;
        const matchDay = selectedDay
          ? date.getDate() === Number(selectedDay)
          : true;
        return matchMonth && matchDay;
      })
    : [];

  return (
    <div className="revenue-container">
      <Link to="/cleanermain" className="order-list__back">
        Назад
      </Link>
      <h2 className="revenue-container__title">Доходы</h2>

      <div className="revenue-filters">
        <select
          className="revenue-filters__month"
          value={selectedMonth}
          onChange={e => setSelectedMonth(e.target.value)}>
          <option value="">Все месяцы</option>
          {months.map((month, index) => (
            <option key={index} value={month}>
              {month}
            </option>
          ))}
        </select>

        <input
          type="number"
          className="revenue-filters__day"
          placeholder="День"
          min="1"
          max="31"
          value={selectedDay}
          onChange={e => setSelectedDay(e.target.value)}
        />
      </div>

      <table className="revenue-table">
        <thead>
          <tr>
            <th>№ заказа</th>
            <th>Доход</th>
            <th>Дата</th>
            <th>Время</th>
            <th>Тип помещения</th>
          </tr>
        </thead>
        <tbody>
          {filteredOrders.length > 0 ? (
            filteredOrders.map(order => {
              const { day, month, year, time } = formatDate(order.date);
              return (
                <tr key={order._id}>
                  <td>{order.orderNumber}</td>
                  <td>{Math.trunc(order.cleanerEarnings)} ₽</td>
                  <td>{`${day} ${month} ${year}`}</td>
                  <td>{time}</td>
                  <td>{order.group}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="5" style={{ textAlign: "center" }}>
                Нет данных
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CleanerRevenue;
