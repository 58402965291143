import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import "./OrderCard.scss";
import {
  fetchUser,
  fetchAssignCleaner,
  fetchRemoveCleaner,
  fetchAddCommission,
  fetchChangePaymentType,
} from "../../redux/slices/user";

import {
  fetchOrder,
  fetchOrderChange,
  fetchOrderDelete,
  fetchOrderCancel,
} from "../../redux/slices/order";

export const OrderCard = ({
  name,
  phone,
  email,
  orderNumber,
  state,
  price,
  group,
  type,
  bathroom_count,
  payment_type,
  comment,
  address,
  intercom,
  room_count,
  date,
  additional_services,
  item,
  editingItemId,
  onEditButtonClick,
  onCancelEdit,
  onSave,
  orderState,
  cleaner,

  setOrderState,
}) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      group: item.group,
      type: item.type,
      bathroom_count: item.bathroom_count,
      additional_services: item.additional_services,
      address: item.address,
      intercom: item.intercom,
      payment_type: item.payment_type,
      comment: item.comment,
      state: item.state ? "Готов заказ" : "Не Готов",
    },
  });

  const onSubmit = e => {
    e.preventDefault();
    const state = orderState === "Готов заказ";
    onSave(item._id, state);
  };

  const handleSave = () => {
    document.location.href = "/order";
  };

  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(fetchUser());
  // }, [dispatch]);

  const users = useSelector(state => {
    const data = state.user.user?.items;
    return Array.isArray(data) ? data : [];
  });
  const cleaners = users.filter(user => user.role?.name === "Уборщик");

  // 🟢 Логика назначения клинера
  const [selectedCleaner, setSelectedCleaner] = useState(null);
  const [loading, setLoading] = useState(false);
  const [removing, setRemoving] = useState(false);

  const [commission, setCommission] = useState(
    cleaner?.commissionPercentage || 0
  );
  const [updatingCommission, setUpdatingCommission] = useState(false);

  const handleAssignCleaner = async () => {
    if (!selectedCleaner) {
      alert("Выберите клинера!");
      return;
    }

    console.log("📌 Отправляем запрос на назначение клинера:", {
      orderId: item._id, // ✅ Передаём ObjectId вместо числа
      cleanerId: selectedCleaner,
      paymentType: "percentage",
    });

    setLoading(true);

    const response = await dispatch(
      fetchAssignCleaner({
        orderId: item._id, // ✅ Передаём `ObjectId`, а не число
        cleanerId: selectedCleaner,
        paymentType: "percentage",
      })
    );

    setLoading(false);

    if (response.error) {
      alert("Ошибка: " + response.payload);
    } else {
      alert("Клинер успешно назначен!");
    }
  };

  const handleRemoveCleaner = async () => {
    if (!selectedCleaner) {
      alert("Выберите клинера!");
      return;
    }
    setRemoving(true);

    const response = await dispatch(fetchRemoveCleaner(item._id));

    setRemoving(false);

    if (response.error) {
      alert("Ошибка: " + response.payload);
    } else {
      alert("Клинер удалён из заказа!");
    }
  };

  // 🟢 Изменение commissionPercentage
  const handleChangeCommission = async percentage => {
    if (!cleaner?._id) {
      alert("Сначала назначьте клинера");
      return;
    }

    setUpdatingCommission(true);
    setCommission(percentage); // Обновляем UI сразу

    const response = await dispatch(
      fetchAddCommission({
        id: cleaner._id,
        commissionPercentage: percentage,
      })
    );

    setUpdatingCommission(false);

    if (response.error) {
      alert("Ошибка: " + response.payload);
    } else {
      alert("Комиссия обновлена!");
    }
  };

  const [updatingPaymentType, setUpdatingPaymentType] = useState(false);

  const handleChangePaymentType = async type => {
    if (!cleaner?._id) {
      alert("Сначала назначьте клинера");
      return;
    }

    setUpdatingPaymentType(true);

    const response = await dispatch(
      fetchChangePaymentType({
        id: cleaner._id,
        paymentType: type,
      })
    );

    setUpdatingPaymentType(false);

    if (response.error) {
      alert("Ошибка: " + response.payload);
    } else {
      alert("Тип оплаты обновлён!");
    }
  };

  const handleUpdateOrder = async (newState, newIsCanceled = null) => {
    try {
      console.log("📌 Отправляем обновление заказа:", {
        id: item._id,
        state: newState,
        isCanceled: newIsCanceled,
      });

      await dispatch(
        fetchOrderChange({
          id: String(item._id),
          state: newState,
          ...(newIsCanceled !== null && { isCanceled: newIsCanceled }),
        })
      );
      alert("Статус заказа обновлён!");
      // перезагрузка страницы
      document.location.reload();
    } catch (error) {
      alert("Ошибка при обновлении заказа");
    }
  };

  const handleDeleteOrder = async () => {
    if (!window.confirm("Вы точно хотите удалить заказ?")) return;
    try {
      console.log("📌 Отправляем удаление заказа:", { id: item._id });

      await dispatch(fetchOrderDelete(String(item._id)));

      alert("Заказ удалён!");
      document.location.reload();
    } catch (error) {
      alert("Ошибка при удалении заказа");
    }
  };

  return (
    <div className="order-card">
      <div className="order-card__section order-card__section--user">
        <h2 className="order-card__title">Заказ № {orderNumber}</h2>
        <p className="order-card__user-name">
          {name}
          {/* <span className="order-card__user-role">— Пользователь</span> */}
        </p>
        <p className="order-card__user-contact">{phone}</p>
        <p className="order-card__user-email">{email}</p>
        {/* <p className="order-card__date">от 01.01.2025</p> */}
        <h3 className="order-card__subtitle">Стоимость</h3>
        <p className="order-card__price">{price} руб</p>

        <p>
          <strong>Оплата:</strong> {payment_type}
        </p>
        <p>
          <strong>
            Статус Заказа:{" "}
            {state === false ? (
              <span style={{ color: "red" }}>Не Готов</span>
            ) : (
              <span style={{ color: "green" }}>Готов заказ</span>
            )}
          </strong>
        </p>
        <p>Заказ {item.isCanceled ? "отменён" : "не отменён"} </p>

        <div className="order-card__actions">
          {/* Изменение состояния заказа */}
          <button
            onClick={() => handleUpdateOrder(!state)}
            className="order-card__save-button">
            {state ? "Пометить как не готов" : "Пометить как готов"}
          </button>

          {/* Отмена заказа */}
          <button
            onClick={() => handleUpdateOrder(state, true)}
            className="order-card__save-button"
            disabled={item.isCanceled}>
            {item.isCanceled ? "Заказ уже отменён" : "Отменить заказ"}
          </button>

          {/* Удаление заказа (только если isCanceled === true) */}
          <button
            onClick={handleDeleteOrder}
            className="order-card__save-button"
            disabled={!item.isCanceled}>
            Удалить заказ
          </button>
        </div>
      </div>

      <div className="order-card__section order-card__section--address">
        <h3 className="order-card__subtitle">Адрес Заказа</h3>
        <p>
          <strong>Дата уборки:</strong> {date}
        </p>
        <p>Город: {address.city}</p>
        <p>Улица: {address.street}</p>
        <p>Дом: {address.house}</p>
        <p>Квартира: {address.apartment}</p>
        <p>Домофон: {intercom}</p>
        <p style={{ wordWrap: "break-word", width: "210px" }}>
          Пожелания по заказу - <br /> {comment}
        </p>
      </div>

      <div className="order-card__section order-card__section--details">
        <h3 className="order-card__subtitle">Информация о заказе</h3>
        <p>Формат уборки - {type}</p>
        <p>Объект - {group}</p>
        <p>Комнаты - {room_count}</p>
        <p>Санузел - {bathroom_count}</p>
        <p>
          Дополнительные услуги:
          <ul className="order-card__services">
            {Array.isArray(additional_services) &&
              additional_services.map(service => (
                <li key={service.id}>
                  <div>
                    {service.name} - [{service.count}] - ({service.price}₽)
                  </div>
                </li>
              ))}
          </ul>
        </p>
      </div>

      <div className="order-card__section order-card__section--cost">
        <div
          style={{
            height: "107px",
            overflow: "overlay",
          }}>
          {cleaners.map(cleaner => (
            <div key={cleaner._id} className="order-card__cleaner">
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                }}>
                <input
                  type="radio"
                  name="cleaner"
                  value={cleaner._id}
                  onChange={() => setSelectedCleaner(cleaner._id)}
                />
                {cleaner.email}
              </label>
            </div>
          ))}
        </div>
        <button
          onClick={handleAssignCleaner}
          disabled={loading}
          className="order-card__save-button">
          {loading ? "Назначение..." : "Назначить клинера"}
        </button>

        <button
          onClick={handleRemoveCleaner}
          disabled={removing}
          className="order-card__save-button">
          {removing ? "Удаление..." : "Удалить клинера"}
        </button>
        <p className="order-card__fix-order">
          Клинер:{" "}
          {cleaner?.name
            ? cleaner.name
            : cleaner?.email
            ? cleaner.email
            : "Клинер не назначен"}
        </p>
        <div className="order-card__cleaner-selector">
          <div>
            {cleaner && (
              <div className="order-card__cleaner-selector">
                <span>
                  Тип оплаты:{" "}
                  {cleaner?.paymentType === "fixed"
                    ? "Фиксированный"
                    : "Процент"}
                </span>
                <div className="order-card__payment-buttons">
                  <button
                    onClick={() => handleChangePaymentType("fixed")}
                    disabled={
                      cleaner?.paymentType === "fixed" || updatingPaymentType
                    }
                    className={`order-card__save-button ${
                      cleaner?.paymentType === "fixed" ? "active" : ""
                    }`}>
                    Фиксированный
                  </button>
                  <button
                    onClick={() => handleChangePaymentType("percentage")}
                    disabled={
                      cleaner?.paymentType === "percentage" ||
                      updatingPaymentType
                    }
                    className={`order-card__save-button ${
                      cleaner?.paymentType === "percentage" ? "active" : ""
                    }`}>
                    Процент
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="order-card__cleaner-selector">
          <span>
            Клинер: <span className="order-card__discount">{commission}%</span>{" "}
          </span>
          <div className="order-card__commission-buttons">
            {[24, 30, 35].map(percentage => (
              <button
                key={percentage}
                onClick={() => handleChangeCommission(percentage)}
                disabled={updatingCommission}
                className={`order-card__save-button ${
                  commission === percentage ? "active" : ""
                }`}
                style={{
                  margin: "0 1px",
                }}>
                {percentage}%
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
