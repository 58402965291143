import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUser,
  fetchUserDelete,
  updateUserBalance,
} from "../../redux/slices/user";
import { Navigate } from "react-router-dom";
import { selectIsAuth } from "../../redux/slices/auth";
import { useForm } from "react-hook-form";
import { fetchRoleChange } from "../../redux/slices/role";
import UsersCard from "../../entities/UsersCard/UsersCard";

const Users = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [searchPhoneNumber, setSearchPhoneNumber] = useState("");
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);
  const users = Object.values(user.items);

  useEffect(() => {
    setIsLoading(true); // Устанавливаем, что загрузка началась
    dispatch(fetchUser())
      .then(() => {
        setIsLoading(false); // Загрузка завершена
      })
      .catch(error => {
        setIsLoading(false); // Если произошла ошибка, также устанавливаем, что загрузка завершена
        setError("Не удалось загрузить заказы. Попробуйте еще раз.");
      });
  }, [dispatch]);

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      role: "",
    },
  });

  const filterUsers = (arr, search) => {
    return arr.filter(item =>
      ["phone_number", "email", "name", "address"].some(
        prop => item[prop] && item[prop].includes(search)
      )
    );
  };

  const filteredUsers = filterUsers(users, searchPhoneNumber);

  const onSubmit = async ({ id, role }) => {
    await dispatch(fetchRoleChange({ id, rules: { add: false, role } }));
    dispatch(fetchUser());
  };

  const handleRoleChange = (id, role) => {
    setValue("role", role); // устанавливаем выбранную роль в форме
    onSubmit({ id, role }); // отправляем запрос на изменение роли
  };

  const deleteUser = id => {
    dispatch(fetchUserDelete(id));
  };

  const handleAddBonus = async (e, id) => {
    console.log(e.target.elements["bonus"].value, id);
    await dispatch(
      updateUserBalance({ id, bonus: e.target.elements["bonus"].value })
    );
    dispatch(fetchUser());
  };

  const isAuth = useSelector(selectIsAuth);
  // if (!localStorage.getItem('token') && !isAuth) {
  //   return <Navigate to="/auth" />
  // }

  if (isLoading) {
    return <h1>Загрузка...</h1>;
  }

  return (
    <div className="search">
      <h3>Пользователи - {filteredUsers.filter(user => user.name).length}</h3>

      <div className="search__container">
        <input
          type="text"
          placeholder="Введите телефон / Почту / Имя / Адрес"
          value={searchPhoneNumber}
          onChange={e => setSearchPhoneNumber(e.target.value)}
        />
      </div>
      {filteredUsers.map(user => (
        <UsersCard
          key={user.id}
          userId={user._id}
          userName={user.name}
          phone={user.phone_number}
          email={user.email}
          address={user.address}
          balance={user.balance}
          role={user.role}
          bonusBalance={user.bonusBalance}
          onRoleChange={role => handleRoleChange(user.id, role)}
          onDelete={() => deleteUser(user.id)}
          onAddBonus={bonus => handleAddBonus(bonus, user.id)}
        />
      ))}
      {/* <div className="clients">
        {filteredUsers.map((item, index) => (
          <div key={index} className="client">
            <div className="client__container">
              <div className="client__text">Email: {item.email}</div>
              <div className="client__text">Имя: {item.name}</div>
              <div className="client__text">Телефон: {item.phone_number}</div>
              <div className="client__text">Бонусы: {item.bonusBalance}</div>
            </div>
            <div className="client__container">
              <div className="client__text">Баланс: {item.balance}</div>
              <div className="client__text">Роль: {item?.role?.name}</div>
              <div className="client__text">
                Адрес:
                {item.address.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })}
              </div>
              <div className="client__text">
                Адрес:
                {item.address.length > 0 ? (
                  <div>
                    Город: {item.address[0]}
                    <br />
                    Улица: {item.address[1]}
                    <br />
                    Домофон: {item.address[2]}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="client__container">
              <form
                onSubmit={handleSubmit(data =>
                  onSubmit({ id: item._id, role: data.role })
                )}>
                <select
                  value={item?.role?.name || ""}
                  onChange={e => handleRoleChange(item._id, e.target.value)}>
                  <option value="Администратор">Администратор</option>
                  <option value="Менеджер">Менеджер</option>
                  <option value="Пользователь">Пользователь</option>
                  <option value="Уборщик">Уборщик</option>
                </select>
                <button type="submit" className="button">
                  Изменить
                </button>
              </form>
              <button onClick={() => deleteUser(item._id)} className="button">
                Удалить
              </button>
            </div>
            <div className="client__container">
              <form onSubmit={e => handleAddBonus(e, item._id)}>
                <input name="bonus" type="number" />
                <button type="submit" className="button">
                  Добавить бонусы
                </button>
              </form>
            </div>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default React.memo(Users);
