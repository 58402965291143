import { useState } from "react";
import "./RegisterCleaner.scss";
import { fetchRegisterEmail } from "../../redux/slices/auth";
import { useDispatch, useSelector } from "react-redux";

export default function RegisterCleaner() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { status } = useSelector(state => state.auth);

  const handleRegister = async e => {
    e.preventDefault();
    dispatch(fetchRegisterEmail({ email, password }));
  };

  return (
    <div className="register-container">
      <div className="register-box">
        <h2>Регистрация</h2>
        <form onSubmit={handleRegister}>
          <div className="input-group">
            <label>Email</label>
            <input
              type="email"
              placeholder="Введите email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label>Пароль</label>
            <input
              type="password"
              placeholder="Введите пароль"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>
          <button type="submit">Зарегистрироваться</button>
        </form>
        {status === "loading" && <p>Загрузка...</p>}
        {status === "error" && <p>Ошибка регистрации</p>}
        {/* {message && <p className="message">{message}</p>} */}
      </div>
    </div>
  );
}
